import * as React from "react"
import { useState, useLayoutEffect } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import { Helmet } from "react-helmet"
import { BiSearchAlt2 } from "react-icons/bi"
import { BsArrowUp } from "react-icons/bs"
import { BlogData } from "../components/blogDetails"

import "../components/css/blog.scss"
import blogimg from "../images/blog/blogimg.jpg"

const Video = () => {
  const [searchTerm, setSearchTerm] = useState("")
  const [selectByValue, setSelectByValue] = useState("") // Define selectByValue state

  // Copy blog data to a state variable
  const [blogList, setBlogList] = React.useState([])

  React.useLayoutEffect(() => {
    setBlogList([...BlogData])
  }, [])

  console.log("blogList", blogList)

  // Calculate estimated read time
  const wordsPerMinute = 120

  const [visibleItemsCount, setVisibleItemsCount] = useState(8) // Initially show 8 items

  const filteredBlogList = blogList.filter(filterAccord => {
    const lowerCaseTitle = filterAccord.blogTitle?.toLowerCase();
    const lowerCaseCity = filterAccord.blogCity?.toLowerCase();
    return (
      (searchTerm === "" ||
        lowerCaseTitle.includes(searchTerm.toLowerCase())) &&
      (selectByValue === "" ||
        lowerCaseCity === selectByValue.toLowerCase())
    );
  });

  return (
    <Layout>
      <Seo title="EdgeQ | Blog" />
      <Helmet>
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Company - EdgeQ" />
        <meta
          property="og:description"
          content="our passion About EdgeQ EdgeQ is a leading innovator creating the industry&#8217;s first 5G Base-Station-on-a-Chip. Led by seasoned executives from Qualcomm, Intel, and Broadcom who have delivered industry-transforming technologies (4G/5G, WiFi, Wimax, Artificial Intelligence, Cloud Servers) for the last few decades, EdgeQ is inventing a new paradigm within the wireless infrastructure industry. Our vision is &hellip; Company Read More &raquo;"
        />
        <meta property="og:url" content="https://edgeq.io/company/" />
        <meta property="og:site_name" content="EdgeQ" />
        <meta
          property="article:modified_time"
          content="2021-03-08T21:44:16+00:00"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:label1" content="Est. reading time" />
        <meta name="twitter:data1" content="4 minutes" />
      </Helmet>

      <section className="blog_sec_one">
        <Container>
          <Row>
            <Col md={5}>
              <div className="left">
                <div className="company-heading blog-heading">
                  <h2 data-aos="fade-right">
                    <span className="orange uppercase">5G</span> hub
                  </h2>
                  <h5>
                    An EdgeQ blog focused on making what’s complicated
                    accessible and simple
                  </h5>
                  {/* <p>
                    Lorem ipsum dolor sit consectetur Ut libero urna tellus
                    donec auctor Morbi sit natoque in sit bibendum Hac egestas
                    elementum nisl non At vero.
                  </p> */}
                </div>
              </div>
            </Col>

            <Col md={7}>
              <div className="right">
                <img src={blogimg} alt={"blogimg"} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="career-second-sec blog_second_sec">
        <Container>
          <Row>
            <Col>
              <div className="location-sec">
                <div className="Location-box">
                  <svg
                    width="11"
                    height="7"
                    viewBox="0 0 11 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 1.44531L6.22546 5.21985C5.7797 5.66562 5.05026 5.66562 4.60449 5.21985L0.829956 1.44531"
                      stroke="#999999"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <select
                    name="location"
                    onChange={evt => setSelectByValue(evt.target.value)}
                    value={selectByValue} // Add this line to make the select work
                  >
                    <option value="">All Categories</option>
                    {/* <option value="Smart City">Smart City</option> */}
                  </select>
                </div>
                <div className="Location-search">
                  <form action="/">
                    <input
                      type="text"
                      value={searchTerm}
                      placeholder="Search"
                      name="search"
                      onChange={evt => setSearchTerm(evt.target.value)}
                    />
                    <div className="search-icon">
                      <BiSearchAlt2 />
                    </div>
                  </form>
                </div>
              </div>
            </Col>
          </Row>
          <div className="blog_sec_two">
            <Row>
            {filteredBlogList.slice(0, visibleItemsCount).map((bloglist, index) => (
                  <Col md={4} key={bloglist.id}>
                    <div className="Blog_Box">
                      <a href={bloglist.blogLink}>
                        <div className="Blog_Box_Img">
                          <img src={bloglist.imgURL} alt={`img-${index}`} />
                          {bloglist.blogCity ? (
                            <p>{bloglist.blogCity}</p>
                          ) : null}
                        </div>
                        <div className="Blog_Box_Content">
                          <h2>{bloglist.blogTitle}</h2>
                          <p>{bloglist.blogDetails}</p>
                          <div className="date_time">
                            <h5>{bloglist.blogDate}</h5>
                            <h6>
                              <svg
                                width="19"
                                height="18"
                                viewBox="0 0 19 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.5 0C11.8869 0 14.1761 0.948211 15.864 2.63604C17.5518 4.32387 18.5 6.61305 18.5 9C18.5 11.3869 17.5518 13.6761 15.864 15.364C14.1761 17.0518 11.8869 18 9.5 18C7.11305 18 4.82387 17.0518 3.13604 15.364C1.44821 13.6761 0.5 11.3869 0.5 9C0.5 6.61305 1.44821 4.32387 3.13604 2.63604C4.82387 0.948211 7.11305 0 9.5 0ZM9.5 0.947368C7.36431 0.947368 5.31609 1.79577 3.80593 3.30593C2.29577 4.81609 1.44737 6.86431 1.44737 9C1.44737 11.1357 2.29577 13.1839 3.80593 14.6941C5.31609 16.2042 7.36431 17.0526 9.5 17.0526C10.5575 17.0526 11.6046 16.8443 12.5816 16.4397C13.5586 16.035 14.4463 15.4418 15.1941 14.6941C15.9418 13.9463 16.535 13.0586 16.9397 12.0816C17.3443 11.1046 17.5526 10.0575 17.5526 9C17.5526 6.86431 16.7042 4.81609 15.1941 3.30593C13.6839 1.79577 11.6357 0.947368 9.5 0.947368ZM9.02632 3.78947H9.97368V8.92421L14.4263 11.4916L13.9526 12.3158L9.02632 9.47368V3.78947Z"
                                  fill="#6A6A6A"
                                />
                              </svg>
                              {Math.ceil(
                                bloglist?.blogDetails1?.split(" ")?.length /
                                  wordsPerMinute
                              )}{" "}
                              min
                              {Math.ceil(
                                bloglist?.blogDetails1?.split(" ")?.length /
                                  wordsPerMinute
                              ) !== 1
                                ? "s"
                                : ""}


                            </h6>
                          </div>
                        </div>
                      </a>
                    </div>
                  </Col>
                ))}
            </Row>

            {filteredBlogList.length === 0 && (
              <div className="no-posts-found">No Posts Founds</div>
            )}

            {visibleItemsCount < filteredBlogList.length && (
              <div className="form-btn">
                <button
                  onClick={() =>
                    setVisibleItemsCount(prevCount => prevCount + 4)
                  }
                >
                  <span className="arowup">
                    <BsArrowUp />
                  </span>
                  Load More
                </button>
              </div>
            )}
          </div>
        </Container>
      </section>
    </Layout>
  )
}
export default Video;
